import { useMemo } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
export function initAPI(fetcher, axiosClient) {
    async function makeRequest(method, url, data, config = { requiresTokenAuthentication: true }) {
        if (config.requiresClientVerification) {
            config.headers = { ...(config.headers || {}), 'x-aiq-client-verification': true };
        }
        else if (config.requiresTokenAuthentication) {
            config.headers = { ...(config.headers || {}), 'x-aiq-token-auth': true };
        }
        const requestConfig = {
            ...(config || {}),
            url,
            method,
            data
        };
        if (config && config.useAbsoluteUrl) {
            return axios(requestConfig).then(res => res.data);
        }
        try {
            const res = await axiosClient(requestConfig);
            return res.data;
        }
        catch (e) {
            throw e;
        }
    }
    async function post(url, data, config = { requiresTokenAuthentication: true }) {
        return makeRequest('post', url, data, config);
    }
    ;
    async function put(url, data, config = { requiresTokenAuthentication: true }) {
        return makeRequest('put', url, data, config);
    }
    async function patch(url, data, config = { requiresTokenAuthentication: true }) {
        return makeRequest('patch', url, data, config);
    }
    async function del(url, config = { requiresTokenAuthentication: true }) {
        return makeRequest('delete', url, undefined, config);
    }
    async function get(url, config = { requiresTokenAuthentication: true }) {
        return makeRequest('get', url, undefined, config);
    }
    // Front facing method to access data from the Angling iQ api
    function useApi(key, opts) {
        const headers = {};
        if (opts && opts.disableTokenAuthentication) {
            headers['x-aiq-token-auth'] = false;
        }
        else {
            headers['x-aiq-token-auth'] = true;
        }
        const { data, isValidating, mutate, ...rest } = useSWR(key, fetcher(headers), opts);
        const newMutate = async (newData, _opts) => {
            if (!data || !data.meta) {
                return Promise.resolve(undefined);
            }
            const mutatedData = await mutate(newData ? { meta: data.meta, data: newData } : undefined, _opts);
            return mutatedData?.data;
        };
        return {
            data: data && data.data !== undefined ? data.data : data && opts?.fallbackData ? data : undefined,
            meta: data ? data.meta : undefined,
            pagination: data ? data.pagination : undefined,
            isValidating,
            mutate: newMutate,
            ...rest
        };
    }
    function useApiPagination(getKeyFn, opts) {
        const headers = {};
        if (opts && opts.disableTokenAuthentication) {
            headers['x-aiq-token-auth'] = false;
        }
        else {
            headers['x-aiq-token-auth'] = true;
        }
        const { data, mutate, setSize, size, ...rest } = useSWRInfinite(getKeyFn, fetcher(headers), {
            ...opts,
            revalidateFirstPage: false,
            fallbackData: opts?.fallbackData ? [{ data: opts.fallbackData }] : undefined
        });
        const isLoadingMore = (size > 0 && data && typeof data[size - 1] === 'undefined') || false;
        // const newMutate = async (newData?: T[] | undefined | Promise<T[] | undefined> | MutatorCallback<T[]>, _opts?: boolean | MutatorOptions<T[]>) => {
        // 	const mutatedData = await mutate(
        // 		(newData ? [{ data: newData }] : undefined) as APIResponse<T[]>[] | undefined,
        // 		_opts as boolean | MutatorOptions<APIResponse<T[]>[]>
        // 	);
        // 	if (!mutatedData) {
        // 		return undefined;
        // 	}
        // 	return Promise.resolve(mutatedData.map(x => x.data).flat());
        // };
        const newMutate = async (newData, _opts) => {
            const mutatedData = await mutate((async (prevData) => {
                const resolvedNewData = await newData;
                if (typeof resolvedNewData === "function") {
                    const callbackFn = resolvedNewData;
                    const transformedData = callbackFn(prevData?.map((x) => x.data).flat() ?? undefined);
                    return [{ data: transformedData }];
                }
                return resolvedNewData ? [{ data: resolvedNewData }] : undefined;
            }), _opts);
            if (!mutatedData) {
                return undefined;
            }
            return mutatedData.flatMap((x) => x.data);
        };
        const flattenedData = useMemo(() => 
        // if (!data) {
        // 	if (opts?.fallbackData) {
        // 		return opts.fallbackData;
        // 	}
        // 	return undefined;
        // }
        data?.map(x => x.data).flat(), [data]);
        const pagination = data && data.length > 0 ? data[data.length - 1].pagination : undefined;
        return { data: flattenedData, mutate: newMutate, pagination, size, setSize, isLoadingMore, ...rest };
    }
    return {
        useApi,
        useApiPagination,
        post,
        put,
        patch,
        get,
        del
    };
}
;
