import asUrlString from '../utils/asUrlString';
export const getBaitById = (baitId) => `/baits/${baitId}`;
export const createBait = () => '/baits';
export const updateBait = (baitId) => `/baits/${baitId}`;
export const deleteBait = (baitId) => `/baits/${baitId}`;
export const searchBaits = (q) => {
    const query = {
        ...q,
        system: true
    };
    return asUrlString('/baits', query);
};
export const getBaitSizes = () => `/baits/sizes`;
export const getBaitTypes = () => `/baits/types`;
export const discoverBaits = () => '/baits/self/feed';
export const getBaitsByUser = (userId, q) => asUrlString(`/baits/users/${userId}`, q);
export const getTopBaits = (q) => asUrlString('/baits/top', q);
export const getRecentlyUsedBaits = (q) => {
    return asUrlString('/baits/recent', q);
};
export const getCatchesByBait = (baitId, q) => asUrlString(`/baits/${baitId}/catches`, q);
export const createBaitLike = (baitId) => `/baits/${baitId}/likes`;
export const deleteBaitLike = (baitId) => `/baits/${baitId}/likes`;
export const getBaitLikes = (baitId, q) => asUrlString(`/baits/${baitId}/likes`, q);
export const getCommentsByBait = (baitId, q) => {
    return asUrlString(`/baits/${baitId}/comments`, q);
};
export const createBaitComment = (baitId) => `/baits/${baitId}/comments`;
export const updateBaitComment = (commentId) => `/baits/comments/${commentId}`;
export const deleteBaitComment = (commentId) => `/baits/comments/${commentId}`;
export const getSystemWaterwaysByBait = (baitId, q) => asUrlString(`/baits/${baitId}/system-waterways`, q);
export const getSpeciesByBait = (baitId, q) => asUrlString(`/baits/${baitId}/species`, q);
