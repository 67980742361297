import asUrlString from "../utils/asUrlString";
export const getPermitSeasons = (systemWaterwayId) => `/b/permits/system-waterways/${systemWaterwayId}/seasons`;
export const getLatestPermitSeason = (systemWaterwayId) => `/b/permits/system-waterways/${systemWaterwayId}/seasons/latest`;
export const createPermitSeason = (systemWaterwayId) => `/b/permits/system-waterways/${systemWaterwayId}/seasons`;
export const updatePermitSeason = (systemWaterwayId, seasonId) => `/b/permits/system-waterways/${systemWaterwayId}/seasons/${seasonId}`;
export const deletePermitSeason = (systemWaterwayId, seasonId) => `/b/permits/system-waterways/${systemWaterwayId}/seasons/${seasonId}`;
export const updatePermitSettings = (systemWaterwayId) => `/b/permits/system-waterways/${systemWaterwayId}/settings`;
export const getPermitById = (permitId) => `/b/permits/${permitId}`;
export const createPermit = (systemWaterwayId) => `/b/permits/system-waterways/${systemWaterwayId}`;
export const deletePermit = (permitId, q) => asUrlString(`/b/permits/${permitId}`, q);
export const patchPermit = (permitId) => `/b/permits/${permitId}`;
export const getPermitsBySystemWaterway = (systemWaterwayId, opts = {}) => {
    return asUrlString(`/b/permits/system-waterways/${systemWaterwayId}`, opts);
};
export const bookPermit = (permitId) => `/b/permits/${permitId}/book`;
export const getNumberOfRodsPossibleForPermit = (systemWaterwayId) => `/b/permits/system-waterways/${systemWaterwayId}/rod-possibility`;
export const getSeasonPartForDate = (systemWaterwayId, timestamp) => `/b/permits/system-waterways/${systemWaterwayId}/season-part/${timestamp}`;
export const getRodInformation = (permitId) => `/b/permits/${permitId}/rod-information`;
export const updatePermitAnglers = (permitId) => `/b/permits/${permitId}/anglers`;
export const updatePermitGuide = (permitId) => `/b/permits/${permitId}/guide`;
export const getPermitsSummary = (businessAccountId, q) => asUrlString(`/b/permits/business-accounts/${businessAccountId}/summary`, q);
