import asUrlString from '../utils/asUrlString';
export const getServicesByBusinessUser = () => `/b/services/self`;
export const getServicesByAccount = (businessAccountId) => `/b/business-accounts/${businessAccountId}/services`;
export const getBusinessUsersByAccount = (businessAccountId) => `/b/business-accounts/${businessAccountId}/users`;
export const inviteUserToService = (businessAccountServiceId) => `/b/services/${businessAccountServiceId}/invitations`;
export const deleteUserFromService = (businessAccountServiceUserId) => `/b/service-users/${businessAccountServiceUserId}`;
export const createBusinessAccount = () => `/b/business-accounts/signup`;
export const updateBusinessAccount = (businessAccountId) => `/b/business-accounts/${businessAccountId}`;
export const createBusinessUser = () => `/b/business-accounts/users/signup`;
export const checkEmailAvailability = () => '/b/business-accounts/users/email/availability';
export const verifyBusinessAccountServiceInvitation = (token) => asUrlString(`/b/business-accounts/invitations/verification`, { invitation_token: token });
export const forgotBusinessUserPassword = () => `/b/business-accounts/forgot-password`;
export const verifyForgotBusinessUserPasswordToken = (token) => asUrlString(`/b/business-accounts/forgot-password/verification`, { token });
export const resetForgotBusinessUserPassword = (token) => asUrlString(`/b/business-accounts/forgot-password/reset`, { token });
export const resetPasswordForTabletUser = (businessAccountId, systemWaterwayId) => asUrlString(`/b/business-accounts/${businessAccountId}/tablet-user/system-waterways/${systemWaterwayId}/password/reset`);
export const getBankAccountsByBusinessAccount = (businessAccountId) => `/b/business-accounts/${businessAccountId}/bank-accounts`;
export const createBankAccount = (businessAccountId) => `/b/business-accounts/${businessAccountId}/bank-accounts`;
export const updateBankAccount = (businessAccountId, wireTransferDetailsId) => `/b/business-accounts/${businessAccountId}/bank-accounts/${wireTransferDetailsId}`;
export const deleteBankAccount = (businessAccountId, wireTransferDetailsId) => `/b/business-accounts/${businessAccountId}/bank-accounts/${wireTransferDetailsId}`;
export const getSupportedCurrenciesByBusinessAccount = (businessAccountId) => `/b/business-accounts/${businessAccountId}/currencies`;
export const updateStraumurAPIKey = (businessAccountId) => `/b/business-accounts/${businessAccountId}/straumur-api-key`;
export const getGuideSettings = (businessAccountId) => `/b/business-accounts/${businessAccountId}/guide-settings`;
export const updateGuideSettings = (businessAccountId) => `/b/business-accounts/${businessAccountId}/guide-settings`;
