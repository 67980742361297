import asUrlString from '../utils/asUrlString';
export const getSystemWaterways = function (q) {
    return asUrlString('/system-waterways', q);
};
export const getSystemWaterwayById = (systemWaterwayId, q) => {
    return asUrlString(`/system-waterways/${systemWaterwayId}`, q);
};
export const getTopSpotsBySystemWaterway = function (systemWaterwayId, q) {
    return asUrlString(`/system-waterways/${systemWaterwayId}/spots/top`, q);
};
export const getSpotsBySystemWaterway = (systemWaterwayId, q) => {
    return asUrlString(`/system-waterways/${systemWaterwayId}/spots`, q);
};
export const getCatchesBySystemWaterway = function (systemWaterwayId, q) {
    return asUrlString(`/system-waterways/${systemWaterwayId}/catches`, q);
};
export const getMomentsBySystemWaterway = function (systemWaterwayId, q) {
    return asUrlString(`/system-waterways/${systemWaterwayId}/moments`, q);
};
export const getPostsByParentSystemWaterway = function (systemWaterwayId, q) {
    return asUrlString(`/system-waterways/${systemWaterwayId}/parent/posts`, q);
};
export const getSpeciesBySystemWaterway = (systemWaterwayId, q) => asUrlString(`/system-waterways/${systemWaterwayId}/species`, q);
export const getAnglersBySystemWaterway = (systemWaterwayId, q) => {
    return asUrlString(`/system-waterways/${systemWaterwayId}/anglers`, q);
};
export const getSystemWaterwayPreferredSpecies = function (systemWaterwayId) {
    return `/system-waterways/${systemWaterwayId}/species/preferred`;
};
export const getSystemWaterwaySpeciesStatistics = function (systemWaterwayId, q) {
    return asUrlString(`/system-waterways/${systemWaterwayId}/species/statistics`, q);
};
export function getTopBaitsBySystemWaterway(systemWaterwayId, q) {
    return asUrlString(`/system-waterways/${systemWaterwayId}/baits/top`, q);
}
export const getSystemWaterwaySeasons = function ({ id }) {
    return `/system-waterways/${id}/seasons`;
};
export const getLatestSystemWaterwaySeason = function (systemWaterwayId) {
    return `/system-waterways/${systemWaterwayId}/seasons/latest`;
};
export const incrementSystemWaterwayViewCount = function (systemWaterwayId) {
    return `/system-waterways/${systemWaterwayId}/views`;
};
export const getRecentlyUsedSystemWaterways = function () {
    return '/system-waterways/recent';
};
export const putSystemWaterway = (id) => {
    return `/system-waterways/${id}`;
};
export const postSystemWaterway = () => {
    return '/system-waterways';
};
export const discoverSystemWaterways = () => {
    return '/system-waterways/discover';
};
export const getSystemWaterwaysByAdmin = function ({ userId }) {
    return `/system-waterways/admin/${userId}`;
};
export const getUsersWithLogbookAccess = ({ id }) => {
    return `/system-waterways/${id}/logbook-access`;
};
export const getGuidesWithLogbookAccess = ({ id }) => {
    return `/system-waterways/${id}/guides`;
};
export const editPreferredSpecies = ({ id }) => {
    return `/system-waterways/${id}/species/preferred`;
};
export const editLogbookAccess = ({ id }) => {
    return `/system-waterways/${id}/logbook-access`;
};
export const editLogbookGuides = ({ id }) => {
    return `/system-waterways/${id}/guides`;
};
export const getGuidedSystemWaterways = function () {
    return asUrlString('/system-waterways/guides/self');
};
export const getSystemWaterwaysWithLogbookAccess = () => '/system-waterways/logbook-access/self';
export const putSystemWaterwayRelationship = (systemWaterwayId) => {
    return `/system-waterways/${systemWaterwayId}/relationship`;
};
export const getSystemWaterwayFollowers = (systemWaterwayId, q) => {
    return asUrlString(`/system-waterways/${systemWaterwayId}/followers`, q);
};
export const checkIfSystemWaterwayEditor = (systemWaterwayId) => {
    return `/system-waterways/${systemWaterwayId}/editor`;
};
export const checkIfSystemWaterwayAdmin = (systemWaterwayId = 373) => {
    return `/system-waterways/${systemWaterwayId}/admin`;
};
export const subscribeLogbookNotifications = (systemWaterwayId) => {
    return `/system-waterways/${systemWaterwayId}/logbooks/notifications`;
};
export const unsubscribeLogbookNotifications = (systemWaterwayId) => {
    return `/system-waterways/${systemWaterwayId}/logbooks/notifications`;
};
export const getSystemWaterwayChildren = (systemWaterwayId) => {
    return `/system-waterways/${systemWaterwayId}/children`;
};
export const getSystemWaterwayBounds = (systemWaterwayId) => {
    return `/system-waterways/${systemWaterwayId}/bounds`;
};
