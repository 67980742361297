import asUrlString from "../utils/asUrlString";
export const getBookingById = (bookingOfferId, q) => asUrlString(`/b/booking-offers/bookings/${bookingOfferId}`, q);
export const getBookingsByBusinessAccount = (businessAccountId, q) => asUrlString(`/b/booking-offers/bookings/business-accounts/${businessAccountId}`, q);
export const deleteBooking = (bookingOfferId) => asUrlString(`/b/booking-offers/bookings/${bookingOfferId}`);
export const deleteBookingOfferPermit = (bookingOfferId, permitId, q) => asUrlString(`/b/booking-offers/bookings/${bookingOfferId}/permits/${permitId}`, q);
export const getBookingOfferActions = (bookingOfferId) => asUrlString(`/b/booking-offers/${bookingOfferId}/actions`);
export const generateInvoiceTemplates = (bookingOfferId) => asUrlString(`/b/booking-offers/${bookingOfferId}/invoice-templates`);
export const createInvoices = (bookingOfferId) => asUrlString(`/b/booking-offers/${bookingOfferId}/invoices`);
export const getInvoicesByBooking = (bookingOfferId) => asUrlString(`/b/booking-offers/bookings/${bookingOfferId}/invoices`);
export const creditInvoice = (invoiceId) => asUrlString(`/b/booking-offers/invoices/${invoiceId}/credit`);
export const createBookingOfferProduct = (bookingOfferId) => asUrlString(`/b/booking-offers/${bookingOfferId}/products`);
export const updateBookingOfferProduct = (bookingOfferId, bookingOfferProductId) => asUrlString(`/b/booking-offers/${bookingOfferId}/products/${bookingOfferProductId}`);
export const deleteBookingOfferProduct = (bookingOfferId, bookingOfferProductId) => asUrlString(`/b/booking-offers/${bookingOfferId}/products/${bookingOfferProductId}`);
