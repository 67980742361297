import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { initAPI } from '@aiq/api-client';
import { auth, signOut } from './auth';

export * from '@aiq/api-client';

const client = axios.create({
	baseURL: '/api/aiq' // Next.js proxy
});

client.interceptors.response.use(
	response => response,
	async error => {

		// If we get a 401 and we have an active session, log out
		if (error.response && error.response.status === 401 && !error.config._retry) {
			error.config._retry = true;
			const session = await auth();

			if (session) {
				await signOut({ redirectTo: '/login' });
			}

			return Promise.reject(error as Error);
		} else if (error.response && error.response.status === 401) {
			// We have retried the request, but still get 401.. Log out
			// console.log('We have retried the request, but still get 401.. Log out');
			// await signOut({ redirect: true });
		}

		return Promise.reject(error as Error);
	}
);

const fetcher = (headers: AxiosRequestConfig['headers']) => (url: string) => client.get(url, { headers }).then(res => res.data);

export const { useApi, useApiPagination, get, post, put, del, patch } = initAPI(fetcher, client);