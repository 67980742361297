import stripUndefined from './stripUndefined';
// interface QueryType<T> {
// 	[key: keyof T]: string | number | boolean | null | undefined | Array<string | number>;
// }
export default (path, q) => {
    const queryWithValues = stripUndefined(q);
    const keys = Object.keys(queryWithValues);
    if (keys.length > 0) {
        const searchParams = new URLSearchParams();
        // Properly construct query params array if included
        for (let key of keys) {
            const value = queryWithValues[key];
            if (Array.isArray(value)) {
                for (let elem of value) {
                    searchParams.append(key, elem);
                }
            }
            else {
                searchParams.append(key, value);
            }
        }
        return `${path}?${searchParams.toString()}`;
    }
    return path;
};
