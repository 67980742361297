import asUrlString from '../utils/asUrlString';
export const createSpot = () => {
    return `/spots`;
};
export const putSpot = (spotId) => {
    return `/spots/${spotId}`;
};
export const deleteSpot = (spotId) => {
    return `/spots/${spotId}`;
};
export const getSpotById = (spotId) => `/spots/${spotId}`;
export const getSpeciesBySpot = (spotId, q) => asUrlString(`/spots/${spotId}/species`, q);
export const getBaitsBySpot = (spotId, q) => asUrlString(`/spots/${spotId}/baits`, q);
export const getCatchesBySpot = (spotId, q) => asUrlString(`/spots/${spotId}/catches`, q);
export const getSystemWaterwayBySpot = (spotId, q) => asUrlString(`/spots/${spotId}/system-waterway`, q);
export const checkIfSpotEditor = (spotId) => {
    return `/spots/${spotId}/editor`;
};
export const getRecentlyUsedSpots = (systemWaterwayId, q) => asUrlString(`/spots/system-waterways/${systemWaterwayId}/recent`, q);
