import asUrlString from '../utils/asUrlString';
export const putUser = () => `/users`;
export const deleteUser = () => `/users`;
export const putUserUnits = () => `/users/units`;
export const validateUserVerification = () => '/users/verification/validation';
export const userVerification = () => '/users/verification';
export const searchUsers = (q) => {
    const query = {
        ...q,
        phone_number: /^\d+$/.test(q.q)
    };
    return asUrlString('/users/search', query);
};
export const discoverUsers = () => '/users/discover';
export const getUserById = (id) => `/users/${id}`;
export const getUserByUsername = (username) => `/users/username/${username}`;
export const getUserByEmail = (email) => `/users/email/${email}`;
export const getUserFollowers = (userId, q) => asUrlString(`/users/${userId}/followers`, q);
export const getUserFollowing = (userId, q) => asUrlString(`/users/${userId}/following`, q);
export const getFollowRequests = () => `/users/requests`;
export const getUserSystemWaterwaysFollowing = (userId, q) => asUrlString(`/users/${userId}/following/system-waterways`, q);
export const putUserRelationship = (userId) => `/users/${userId}/relationship`;
export const linkFacebook = () => '/users/facebook';
export const unlinkFacebook = () => '/users/facebook';
export const getSystemWaterwayFavourites = () => `/users/system-waterways/favourites`;
export const putSystemWaterwayFavourites = () => `/users/system-waterways/favourites`;
export const deleteSystemWaterwayFavourite = (systemWaterwayId) => `/users/system-waterways/${systemWaterwayId}/favourites`;
export const addSystemWaterwayFavourite = () => `/users/system-waterways/favourites`;
export const getAddressForSelf = () => '/users/self/address';
export const putAddressForSelf = () => '/users/self/address';
