import asUrlString from '../utils/asUrlString';
export const getCatchById = (catchId) => `/catches/${catchId}`;
export const createCatch = () => {
    return `/catches`;
};
export const createBatchCatches = () => {
    return `/catches/batch`;
};
export const putCatch = (id) => {
    return `/catches/${id}`;
};
export const deleteCatch = (id) => {
    return `/catches/${id}`;
};
export const getCatchesTimeSeries = (q) => {
    return asUrlString('/catches/time-series', q);
};
export const getTopCatchesByUser = (userId, q) => {
    return asUrlString(`/catches/users/${userId}/top`, q);
};
export const getCatchesByUser = (userId, q) => {
    return asUrlString(`/catches/users/${userId}`, q);
};
export const getTopCatches = (q) => {
    return asUrlString('/catches/top', q);
};
export const getUserRankings = (userId) => {
    return `/catches/users/${userId}/rankings`;
};
export const getCatchLikes = (catchId, q) => {
    return asUrlString(`/catches/${catchId}/likes`, q);
};
export const createCatchLike = (catchId) => {
    return `/catches/${catchId}/likes`;
};
export const deleteCatchLike = (catchId) => {
    return `/catches/${catchId}/likes`;
};
export const getNearbyEntities = (q) => asUrlString('/catches/geo/nearby', q);
export const getCatchesSummary = (q) => asUrlString('/catches/summary', q);
