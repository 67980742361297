export const getLogbookBaitTypes = function (systemWaterwayId) {
    return `/b/logbooks/${systemWaterwayId}/bait-types`;
};
export const updateLogbookBaitTypes = function (systemWaterwayId) {
    return getLogbookBaitTypes(systemWaterwayId);
};
export const getLogbookSettings = function (systemWaterwayId) {
    return `/b/logbooks/${systemWaterwayId}/settings`;
};
export const updateLogbookSettings = function (systemWaterwayId) {
    return getLogbookSettings(systemWaterwayId);
};
export const getLogLinks = function (systemWaterwayId) {
    return `/b/logbooks/${systemWaterwayId}/links`;
};
export const invalidateLogLink = function (linkId) {
    return `/b/logbooks/links/${linkId}/invalidate`;
};
export const deleteLogLink = function (linkId) {
    return `/b/logbooks/links/${linkId}`;
};
export const createLogLink = function (systemWaterwayId) {
    return `/b/logbooks/${systemWaterwayId}/links`;
};
export const getLogbookSeasons_business = function (systemWaterwayId) {
    return `/b/logbooks/${systemWaterwayId}/seasons`;
};
export const createLogbookSeason = function (systemWaterwayId) {
    return `/b/logbooks/${systemWaterwayId}/seasons`;
};
export const updateLogbookSeason = function (systemWaterwayId, seasonId) {
    return `/b/logbooks/${systemWaterwayId}/seasons/${seasonId}`;
};
export const deleteLogbookSeason = function (systemWaterwayId, seasonId) {
    return `/b/logbooks/${systemWaterwayId}/seasons/${seasonId}`;
};
export const getLogbookReports = function (systemWaterwayId) {
    return `/b/logbooks/${systemWaterwayId}/reports`;
};
export const createLogbookReport = function (systemWaterwayId) {
    return `/b/logbooks/${systemWaterwayId}/reports`;
};
