import asUrlString from "../utils/asUrlString";
export const getCustomerById = (customerId) => asUrlString(`/b/customers/${customerId}`);
export const getCustomersByBusinessAccount = (businessAccountId, q) => asUrlString(`/b/customers/business-accounts/${businessAccountId}`, q);
export const createCustomer = (systemWaterwayId) => asUrlString(`/b/customers/system-waterways/${systemWaterwayId}`);
export const updateCustomer = (customerId) => asUrlString(`/b/customers/${customerId}`);
export const deleteCustomer = (customerId) => asUrlString(`/b/customers/${customerId}`);
export const getCustomerNotes = (customerId) => asUrlString(`/b/customers/${customerId}/notes`);
export const createCustomerNote = (customerId) => asUrlString(`/b/customers/${customerId}/notes`);
export const updateCustomerNote = (customerId, noteId) => asUrlString(`/b/customers/${customerId}/notes/${noteId}`);
export const deleteCustomerNote = (customerId, noteId) => asUrlString(`/b/customers/${customerId}/notes/${noteId}`);
export const getInvoicesByCustomer = (customerId) => asUrlString(`/b/customers/${customerId}/invoices`);
