import { asUrlString } from "..";
export const getLogbookSeasons = function (systemWaterwayId) {
    return `/logbooks/${systemWaterwayId}/seasons`;
};
export const getRecentlyActiveLogbooks = (opts) => {
    return asUrlString('/logbooks/recently-active', opts);
};
export const getLogbookSettingsForUser = function (systemWaterwayId) {
    return `/logbooks/${systemWaterwayId}/settings`;
};
export const getLogbooks = function (opts) {
    return asUrlString('/logbooks', opts);
};
export const getLogbookSummary = function (systemWaterwayId) {
    return `/logbooks/${systemWaterwayId}/summary`;
};
