import asUrlString from '../utils/asUrlString';
export const getSpeciesById = (speciesId) => `/species/${speciesId}`;
export const getSpecies = function () {
    return `/species?count=-1`;
};
export const discoverSpecies = function (q = {}) {
    return asUrlString('/species/recently-caught', q);
};
export const getSpeciesByUser = (userId, q) => {
    return asUrlString(`/species/users/${userId}`, q);
};
export const getCatchesBySpecies = (speciesId, q) => asUrlString(`/species/${speciesId}/catches`, q);
export const getAnglersBySpecies = (speciesId, q) => asUrlString(`/species/${speciesId}/anglers`, q);
export const getRecentlyUsedSpecies = (q) => {
    return asUrlString('/species/recent', q);
};
export const getSpeciesStatistics = (q) => asUrlString('/species/statistics', q);
