import type { IBusinessUser, AuthResponse } from '@aiq/types';
import type { NextAuthConfig, User } from 'next-auth';
import NextAuth, { CredentialsSignin } from 'next-auth';
import { encode, getToken, type JWT } from 'next-auth/jwt';
import CredentialsProvider from 'next-auth/providers/credentials';
import { refreshAccessToken as aiqRefreshAccessToken, logIn } from '@aiq/api-client/routes';
import type { AxiosError } from 'axios';
import { type NextResponse, type NextRequest } from 'next/server';
import type { APIResponse } from '@aiq/api-client';
import { getSession } from 'next-auth/react';
import { get, post } from '@/lib/api';



interface PayloadType {
	username?: string;
	email?: string;
	password: string;
}

export class AuthError extends CredentialsSignin {
	code = "custom";
	constructor(message?: string, errorOptions?: { code?: string }) {
		super(message, errorOptions);
		Object.setPrototypeOf(this, AuthError.prototype);
		this.message = message || "Something went wrong";
		this.code = errorOptions?.code || "";
	}
}

let refreshPromise: Promise<JWT> | null = null;

async function refreshAccessToken(token: JWT) {

	if (!refreshPromise) {
		refreshPromise = (async () => {
			try {
				const res = await post<AuthResponse>(aiqRefreshAccessToken(), { refresh_token: token.refresh_token.token }, { requiresClientVerification: true });

				const data = res.data;
				token.access_token.token = data.access_token.token;
				token.access_token.expiration = data.access_token.expiration;

				return token;
			} catch (e) {
				throw new Error("Error refreshing access token");
			} finally {
				refreshPromise = null;
			}
		})()
	}

	return refreshPromise;
}

export async function updateSessionUser(req: NextRequest, res: NextResponse) {
	console.log('updateSessionUser', process.env.NODE_ENV);
	const token = await getToken({
		req,
		secureCookie: process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test",
		secret: process.env.JWT_SECRET || 'aiq_business_secret',
		salt:
			process.env.NODE_ENV === "production"
				? "__Secure-authjs.session-token"
				: "authjs.session-token",
	});

	if (!token) {
		return null;
	}

	const { data: _user } = await get<IBusinessUser>(`/b/business-accounts/users/self?access_token=${token.access_token.token}`);

	if (_user.id) {
		token.business_user = _user;
	}

	const newSessionToken = await encode({
		secret: process.env.JWT_SECRET || 'aiq_business_secret',
		token: {
			...token,
			business_user: _user
		},
		maxAge: 30 * 24 * 60 * 60, // 30 days, or get the previous token's exp
		salt:
			process.env.NODE_ENV === "production"
				? "__Secure-authjs.session-token"
				: "authjs.session-token",
	})

	const sessionCookie = process.env.NODE_ENV === "production"
		? "__Secure-authjs.session-token"
		: "authjs.session-token";

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (res.cookies) {
		res.cookies.set(sessionCookie, newSessionToken, {
			httpOnly: true,
			secure: process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test",
			sameSite: 'lax',
			maxAge: 30 * 24 * 60 * 60, // 30 days, or get the previous token's exp
		})
	} else {
		console.log('COOOKIES NOT SET!!!!')
	}

	return _user;
}

export async function getJWTToken(req: Request, res: NextResponse) {

	const token = await getToken({
		req,
		secureCookie: process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test",
		secret: process.env.JWT_SECRET || 'aiq_business_secret',
		salt:
			process.env.NODE_ENV === "production"
				? "__Secure-authjs.session-token"
				: "authjs.session-token",
	});

	if (!token) {
		// Sign out? or send 401?
		return null;
	}

	const refreshTokenObj = token.refresh_token;

	// Check if need to refresh access token
	const expiration = new Date(token.access_token.expiration).getTime() - 1000 * 60 * 10; // Decrease by 10 minutes
	const now = Date.now();

	if (now < expiration) {
		return token;
	}

	const result = await refreshAccessToken(token);


	const newSessionToken = await encode({
		secret: process.env.JWT_SECRET || 'aiq_business_secret',
		token: {
			...token,
			access_token: result.access_token,
			refresh_token: refreshTokenObj,
		},
		maxAge: 30 * 24 * 60 * 60, // 30 days, or get the previous token's exp
		salt:
			process.env.NODE_ENV === "production"
				? "__Secure-authjs.session-token"
				: "authjs.session-token",
	})


	const sessionCookie = process.env.NEXTAUTH_URL?.startsWith("https://")
		? "__Secure-authjs.session-token"
		: "authjs.session-token";

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (res.cookies) {
		res.cookies.set(sessionCookie, newSessionToken, {
			httpOnly: true,
			secure: process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test",
			sameSite: 'lax',
			maxAge: 30 * 24 * 60 * 60, // 30 days, or get the previous token's exp
		})
	} else {
		console.log('COOOKIES NOT SET!!!!')
	}

	return token;
}


export async function getAccessToken(req: Request, res: NextResponse) {
	const token = await getJWTToken(req, res);
	return token?.access_token.token;
}


function createAuthOptions(req: NextRequest | undefined): NextAuthConfig {
	return {
		trustHost: true,
		providers: [
			CredentialsProvider({
				credentials: {
					password: { label: 'password', type: 'text' },
					username: { label: 'username', type: 'text' },
					email: { label: 'email', type: 'text' }
				},
				authorize: async (credentials: PayloadType) => {

					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					if (!credentials) {
						return null;
					}

					const payload: PayloadType = {
						password: credentials.password
					};

					if (credentials.username) {
						payload.username = credentials.username;
					} else if (credentials.email) {
						payload.email = credentials.email;
					}

					try {
						const { data, meta } = await post<User>(logIn(), payload, { requiresClientVerification: true });


						if (meta.status_code !== 200) {
							throw new AuthError(meta.message, {
								code: `${meta.code}`,
							});
						}


						return data;
					} catch (e: unknown) {
						const response = e as AxiosError<APIResponse<AuthResponse>>;
						const meta = response.response?.data.meta;
						const ef = new AuthError(meta?.message, {
							code: `${meta?.code}`,
						});

						throw ef
					}

				}
			})
		],
		secret: process.env.JWT_SECRET || 'aiq_business_secret',
		pages: {
			signIn: '/login'
		},
		callbacks: {
			async jwt({ token, user, account, trigger }) {
				// Initial log in
				if (account) {
					return {
						...token,
						...user
					};
				}

				// Check if need to refresh access token
				const expiration = new Date(token.access_token.expiration).getTime() - 1000 * 60 * 10; // Decrease by 10 minutes
				const now = Date.now();

				// Access token hasn't expired
				if (now < expiration) {
					// Fetch the authenticated user if requested...
					if (req?.url === '/api/auth/session/?update=true' || trigger === 'update') {

						try {
							const { data: _user } = await get<IBusinessUser>(`/b/business-accounts/users/self?access_token=${token.access_token.token}`);

							if (_user.id) {
								token.business_user = _user;
							}

							return token;
						} catch (e) {
							return token;
						}
					}

					return {
						...token
					};
				}

				const result = await refreshAccessToken(token);

				token.access_token.token = result.access_token.token;
				token.access_token.expiration = result.access_token.expiration;
				token.refresh_token.token = result.refresh_token.token;
				token.business_user = result.business_user;

				return token;
			},
			session({ session, token }) {
				// We only want to expose the business_user object to the client, and not the tokens
				session.business_user = token.business_user;
				session.is_expired = token.access_token.expiration ? new Date(token.access_token.expiration).getTime() < new Date().getTime() : false;
				return session;
			}
		},
		// Enable debug messages in the console if you are having problems
		debug: process.env.NODE_ENV !== 'production'
	};
}

export const {
	handlers: { GET, POST },
	unstable_update,
	auth,
	signIn,
	signOut
} = NextAuth(req => createAuthOptions(req));
