export const signUp = function () {
    return '/auth/angling-iq/signup';
};
export const logIn = function () {
    return '/auth/angling-iq/login';
};
export const signUpWithApple = function () {
    return '/auth/apple/signup';
};
export const logInWithApple = function () {
    return '/auth/apple/login';
};
export const signUpWithGoogle = function () {
    return '/auth/google/signup';
};
export const logInWithGoogle = function () {
    return '/auth/google/login';
};
export const signUpWithFacebook = function () {
    return '/auth/facebook/signup';
};
export const logInWithFacebook = function () {
    return '/auth/facebook/login';
};
export const refreshAccessToken = function () {
    return '/auth/refresh';
};
export const logOut = function () {
    return '/auth/logout';
};
