import asUrlString from '../utils/asUrlString';
export const getProductsByBusinessAccount = (businessAccountId, q) => {
    return asUrlString(`/b/products/business-accounts/${businessAccountId}`, q);
};
export const getProductById = (productId) => {
    return `/b/products/${productId}`;
};
export const createProduct = (businessAccountId) => {
    return `/b/products/business-accounts/${businessAccountId}`;
};
export const updateProduct = (productId) => {
    return `/b/products/${productId}`;
};
export const deleteProduct = (productId) => {
    return `/b/products/${productId}`;
};
